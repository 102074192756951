import AppRoute, { AppNavigationName } from "~/utils/AppNavigation"

const theUser = ref<UserInfoResponse>()
const userRole = ref<UserRoleResponse>()

export const useUser = () => {
    const role = useRole()
    const router = useRouter()
    const { user, loggedIn } = useAuth() as { user: UserResponse, loggedIn: boolean }

    function getUserDetails() {
        theUser.value = user.user
        return { name: theUser.value.name, email: theUser.value.email, config: user.config }
    }

    function updateUserConfig(data: { config: UserResponse['config'] }) {
        theUser.value = user.user
        user.config = data.config

        return {
            config: user.config
        }
    }

    function updateUserDetails(data: any) {
        theUser.value = user.user as unknown as UserInfoResponse
        theUser.value.name = data.name
        theUser.value.email = data.email
        theUser.value.phone = data.phone
        return {
            name: theUser.value.name,
            email: theUser.value.email,
            phone: theUser.value.phone
        }
    }

    function getUserRoles() {
        userRole.value = user.roles
        return { name: userRole.value.name, label: userRole.value.label }
    }

    function getUserInitials(): string {
        if (!loggedIn) return ''

        let initials = ''
        let words = user?.user.name.split(' ')

        for (let i = 0; i < words.length; i++) {
            initials += words[i].charAt(0)
            if (i == 1) break
        }

        return initials
    }

    function gotoFirstReadyUrl(url: string) {
        // TODO: temporary redirect user to valid url since dashboard page not ready
        if (url && (router.currentRoute.value.path == '/' || router.currentRoute.value.path == '/dashboard')) {
            navigateTo(url)
        }
    }

    /**
     * Get the list of navigation menu that the user is allowed to access, by
     * their role permissions
     *
     * ### Example
     * ```ts
     * getNavMenusByRole()
     * // returns: ['EVENTS', 'ORDERS', 'ACCOUNT']
     * ```
     */
    function getNavMenusByRole(): Array<AppNavigationName> {
        // NOTE: this is to check if user is vilor company and will be change in v3
        const isVilorCompany = (1 == user.user['company_id'])

        const routeRemovedFromPartner = new Set([
            AppNavigationName.LEADS,
            AppNavigationName.CUSTOMERS,
            AppNavigationName.LOG
        ])

        const roles: UserRole = user.roles
        const isUserHavingAllPermissions = role.isSuperAdmin()

        const navMenus: Set<AppNavigationName> = new Set()

        // NOTE: there's additional non-role related fields in the role response
        // which should be skipped
        const skipped = new Set(['id', 'name', 'label', 'company_id'])

        // loop over the roles and add those with permission set to 1
        // NOTE: SSA will have all the menus (except hidden) listed in 'Role.ts'
        for (const [key, isPermissionSet] of Object.entries(roles)) {
            if (skipped.has(key)) continue

            const menus = Role.navMenus[key as keyof UserRole]
            if (!menus) continue

            for (const menu of menus) {
                const isAccessibleByUser = Number.parseInt(isPermissionSet) === 1
                const isMenuHidden = AppRoute.route[menu].hidden

                if (!isMenuHidden && (isAccessibleByUser || isUserHavingAllPermissions)) {
                    navMenus.add(menu)
                }
            }
        }

        // Show 'Account' menu to all users
        navMenus.add(AppNavigationName.ACCOUNT)

        const routes = Array.from(navMenus)
        const route = AppRoute.route[routes[0]]
        gotoFirstReadyUrl(route.url)

        return isVilorCompany
            ? routes
            : routes.filter(route => !routeRemovedFromPartner.has(route))
    }

    return {
        getNavMenusByRole,
        getUserInitials,
        getUserDetails,
        updateUserConfig,
        updateUserDetails,
        getUserRoles,
    }
}
