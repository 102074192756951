import { AppNavigationName } from "./AppNavigation"

export enum RoleEnum {
    SYSTEM_SUPER_ADMIN = "SYSTEM_SUPER_ADMIN",
    COMPANY_SUPER_ADMIN = "COMPANY_SUPER_ADMIN",
    COMPANY_ADMIN = "COMPANY_ADMIN",
    SALES_MANAGER = "SALES_MANAGER",
    SALES_EXECUTIVE = "SALES_EXECUTIVE",
    MARKETING_MANAGER = "MARKETING_MANAGER",
    MARKETING_EXECUTIVE = "MARKETING_EXECUTIVE",
    FINANCE_MANAGER = "FINANCE_MANAGER",
    FINANCE_EXECUTIVE = "FINANCE_EXECUTIVE",
}

export default class Role {

    public static readonly navMenus: Partial<Record<keyof UserRole, Array<AppNavigationName>>> = {
        all_permissions: [AppNavigationName.ORGANIZATION, AppNavigationName.LOG],

        // attend_create: AppNavigationName.
        commission_read: [AppNavigationName.COMMISSIONS],

        customer_read: [AppNavigationName.CUSTOMERS],
        customer_read_by_assigned: [AppNavigationName.CUSTOMERS],

        dashboard_read: [AppNavigationName.DASHBOARD],

        lead_read: [AppNavigationName.LEADS],
        lead_read_by_assigned: [AppNavigationName.LEADS],

        // popup_read: [AppNavigationName.pop

        order_read: [AppNavigationName.ORDERS],
        order_read_by_own: [AppNavigationName.ORDERS],
        order_read_by_assigned_prdt_grp: [AppNavigationName.ORDERS],

        product_read: [AppNavigationName.EVENTS, AppNavigationName.EVENT_GROUPS],
        product_read_by_own: [AppNavigationName.EVENTS],

        user_read: [AppNavigationName.ADMINS],
    }
}
