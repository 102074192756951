export enum AppNavigationName {
    DASHBOARD = 'DASHBOARD',
    EVENTS = 'EVENTS',
    EVENT_GROUPS = 'EVENT_GROUPS',
    ORDERS = 'ORDERS',
    CUSTOMERS = 'CUSTOMERS',
    LEADS = 'LEADS',
    COMMISSIONS = 'COMMISSIONS',
    ADMINS = 'ADMINS',
    ORGANIZATION = 'ORGANIZATION',
    ACCOUNT = 'ACCOUNT',
    LOG = 'LOG',
}

type Route = {
    [key in keyof typeof AppNavigationName]: {
        url: string,
        /** Whether to hide the page for all users */
        hidden?: boolean
    }
}

export default class AppRoute {
    public static readonly route: Route = {
        [AppNavigationName.DASHBOARD]: {
            url: AppUrl.PAGE_DASHBOARD,
            // NOTE: Temporarily remove dashboard page since it's blank
            hidden: true,
        },
        [AppNavigationName.EVENTS]: {
            url: AppUrl.PAGE_EVENTS,
        },
        [AppNavigationName.EVENT_GROUPS]: {
            url: AppUrl.PAGE_EVENT_GROUPS,
        },
        [AppNavigationName.CUSTOMERS]: {
            url: AppUrl.PAGE_CUSTOMERS,
        },
        [AppNavigationName.ORDERS]: {
            url: AppUrl.PAGE_ORDERS,
        },
        [AppNavigationName.LEADS]: {
            url: AppUrl.PAGE_LEADS,
        },
        [AppNavigationName.COMMISSIONS]: {
            url: AppUrl.PAGE_COMMISSIONS,
            hidden: true,
        },
        [AppNavigationName.ADMINS]: {
            url: AppUrl.PAGE_ADMINS,
        },
        [AppNavigationName.ORGANIZATION]: {
            url: AppUrl.PAGE_ORGANIZATION,
        },
        [AppNavigationName.ACCOUNT]: {
            url: AppUrl.PAGE_ACCOUNT,
        },
        [AppNavigationName.LOG]: {
            url: AppUrl.PAGE_AUDIT_LOG,
        },
    }
}
